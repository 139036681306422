import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from '../../common/shared-common.module';
import { CategoryComponent } from './category.component';
import { CategoryDetailComponent } from './category-details/category-detail.component';
import { CataloguePageComponent } from './catalogue/catalogue-page.component';
import { LayoutModule } from '../../layout/layout.module';

const routes: Routes = [
  {
    path: 'category-detail/:id',
    component: CategoryComponent
  },
  {
    path: 'catalogue',
    component: CataloguePageComponent
  }
];

@NgModule({
  imports: [CommonModule, SharedCommonModule, RouterModule.forChild(routes), LayoutModule],
  declarations: [CategoryComponent, CategoryDetailComponent, CataloguePageComponent],
  exports: [
    RouterModule,
    CommonModule,
    SharedCommonModule,
    CategoryComponent,
    CategoryDetailComponent,
    CataloguePageComponent
  ]
})
export class CategoryModule {}