<div
  (click)="clickButtonMore(_data._id, $event)"
  class="{{ theme }} {{ className }}"
  id="card-horizontal-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}">
  <div class="{{ className }}__wrapper-img">
    @if (_data.pictures?.length === 0) {
      <img
        src="/assets/images/empty/empty-icon.svg"
        alt="app image"
        class="{{ theme }} {{ className }}__icon" />
    } @else {
      <img [src]="_data.icon" alt="app icon" class="{{ theme }} {{ className }}__icon" />
    }

    @if (showTagsList && _data.dataTagList !== undefined) {
      @if (_data.dataTagList.includes('Best Seller')) {
        <div class="{{ theme }} {{ className }}__wrapper-img-shape most-purchased">
          <mat-icon
            class="{{ theme }} {{ className }}__wrapper-img-shape-icon most-purchased"
            matTooltip="{{ 'Best Seller' }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="above">
            {{ categoryMostPurchased.icon }}
          </mat-icon>
        </div>
      }
      @if (_data.dataTagList.includes('In library')) {
        <div class="{{ theme }} {{ className }}__wrapper-img-shape purchased">
          <mat-icon
            class="{{ theme }} {{ className }}__wrapper-img-shape-icon purchased"
            matTooltip="{{ 'In library' }}"
            matTooltipClass="{{ theme }} custom-tooltip"
            matTooltipPosition="above">
            check_small
          </mat-icon>
        </div>
      }
    }
  </div>

  <div class="{{ theme }} {{ className }}__wrapper">
    <h3
      class="{{ theme }} {{ className }}__title"
      id="card-horizontal-title"
      matTooltip="{{ _data.name }}"
      matTooltipClass="{{ theme }} custom-tooltip"
      matTooltipPosition="above">
      {{ _data.name }}
    </h3>

    <div
      [innerHtml]="_data.shortDescription | safe: 'html'"
      class="{{ theme }} {{ className }}__text"
      id="card-horizontal-description"
      matTooltip="{{ shortDescriptionTooltip }}"
      matTooltipClass="{{ theme }} custom-tooltip"
      matTooltipPosition="above">
      {{ _data.shortDescription }}
    </div>
    <ul class="{{ theme }} {{ className }}__category-tags-list">
      @for (category of categoriesTags; track $index) {
        @if (category.type === 4) {
          <li class="{{ theme }} {{ className }}__category-tags-list-item order">
            <div class="{{ theme }} {{ className }}__category-tags-list-item-shape">
              <img alt="barbara icon" src="assets/images/icons/b_white.svg" />
            </div>
          </li>
        } @else {
          @if (category.type === 0) {
            <li class="{{ theme }} {{ className }}__category-tags-list-item">
              <span class="{{ theme }} {{ className }}__category-tags-list-item-text">
                {{ category.name }}
              </span>
            </li>
          }
        }
      }
    </ul>
  </div>
</div>