import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedCommonModule } from '../../common/shared-common.module';
import { ResultPageComponent } from './result-page/result-page.component';

const routes: Routes = [
  {
    path: 'results',
    component: ResultPageComponent
  }
];

@NgModule({
  imports: [CommonModule, SharedCommonModule, RouterModule.forChild(routes)],
  declarations: [ResultPageComponent],
  exports: [RouterModule, CommonModule, SharedCommonModule, ResultPageComponent]
})
export class ResultModule {}