<!--Breadcrumb-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-breadcrumbs">
  <div class="{{ className }}__wrapper-row breadcrumbs">
    <div class="{{ className }}__wrapper-col breadcrumbs">
      <div class="{{ className }}__skeleton vertical-breadcrumbs"></div>
    </div>
    <div class="{{ className }}__wrapper-col breadcrumbs">
      <div class="{{ className }}__skeleton vertical-breadcrumbs"></div>
    </div>
  </div>
</div>
<!--Header-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-header">
  <div class="{{ className }}__wrapper-col logo">
    <div class="{{ className }}__skeleton horizontal-logo"></div>
  </div>
  <div class="{{ className }}__wrapper-col title">
    <div class="{{ className }}__skeleton horizontal-title-header"></div>
    <div class="{{ className }}__skeleton horizontal-description-header"></div>
    <div class="{{ className }}__wrapper-row">
      <div class="{{ className }}__skeleton horizontal-barbara-icon"></div>
      <div class="{{ className }}__skeleton horizontal-categories-list"></div>
      <div class="{{ className }}__skeleton horizontal-categories-list"></div>
      <div class="{{ className }}__skeleton horizontal-categories-list"></div>
    </div>
  </div>
  <div class="{{ className }}__wrapper-col button">
    <div class="{{ className }}__skeleton horizontal-button"></div>
  </div>
</div>
<!--Subheader-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-subheader">
  <div class="{{ className }}__wrapper-row detail">
    <div class="{{ className }}__wrapper-col detail">
      <div class="{{ className }}__skeleton horizontal-description-header"></div>
    </div>
    <div class="{{ className }}__wrapper-col detail">
      <div class="{{ className }}__skeleton horizontal-description-header"></div>
    </div>
    <div class="{{ className }}__wrapper-col detail">
      <div class="{{ className }}__skeleton horizontal-description-header"></div>
    </div>
    <div class="{{ className }}__wrapper-col detail">
      <div class="{{ className }}__skeleton horizontal-description-header"></div>
    </div>
    <div class="{{ className }}__wrapper-col detail">
      <div class="{{ className }}__skeleton horizontal-description-header"></div>
    </div>
  </div>
</div>
<!--Carousel-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-carousel">
  <div class="{{ className }}__wrapper-row images">
    <div class="{{ className }}__wrapper-col big">
      <div class="{{ className }}__skeleton vertical-img big"></div>
    </div>
    <div class="{{ className }}__wrapper-col big">
      <div class="{{ className }}__skeleton vertical-img big"></div>
    </div>
    <div class="{{ className }}__wrapper-col big">
      <div class="{{ className }}__skeleton vertical-img big"></div>
    </div>
  </div>
</div>
<!--Tabs-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-tabs">
  <div class="{{ className }}__wrapper-row tabs">
    <div class="{{ className }}__wrapper-col tab">
      <div class="{{ className }}__skeleton vertical-tab"></div>
    </div>
    <div class="{{ className }}__wrapper-col tab">
      <div class="{{ className }}__skeleton vertical-tab"></div>
    </div>
  </div>
</div>
<!--Description-->
<div class="{{ theme }} {{ className }}__wrapper horizontal-long-description">
  <div class="{{ className }}__wrapper-row long-description">
    <div class="{{ className }}__wrapper-col long-description">
      <div class="{{ className }}__skeleton vertical-long-description"></div>
    </div>
    @if (width > 760) {
      <div class="{{ className }}__wrapper-col long-description">
        <div class="{{ className }}__skeleton vertical-long-description"></div>
      </div>
    }
  </div>
</div>
<!--Suggested Apps-->
<div class="{{ className }}__category-suggested">
  @if (times.length > 0) {
    <h3 class="{{ className }}__category-suggested-title">{{ title2 }}</h3>
    <ul class="{{ theme }} {{ className }}__category-list">
      @for (count of times; track $index) {
        @if (count) {
          <li class="{{ theme }} {{ className }}__category-list-item col-{{ times.length }}">
            <skeleton-card
              [type]="'horizontal'"
              [width]="width"
              size="col-{{ times.length }}"></skeleton-card>
          </li>
        }
      }
    </ul>
  }
</div>