import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel,
  CommonLabels
} from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { StorageService } from '../../../../common/services/storage.service';
import { ResizeWindowService } from 'app/common/services/resize-window.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { UtilsService } from '../../../../common/services/utils.service';
import { SortCriteria } from 'app/common/utils/sort-by.pipe';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';
import { Subscription } from 'rxjs';
import { ApplicationsService } from '../../../../common/services/applications.service';
import { cloneDeep } from 'lodash';
import { AuthService } from '../../../../common/services/auth.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  theme = 'marketplace-default';
  className = 'app-home';
  labels: CommonLabels = commonLabels;
  isDesktop: boolean = false;
  width: number = 0;
  isLoading: boolean = true;
  applicationsList: ApplicationModel[] | CategoryApplicationModel[] = [];
  public categoryFeaturedId; //Featured
  public categorySpecialId; //Barbara Basics
  public categoryMostPurchasedId; //Most Purchased
  public categoryPurchasedId; //Already in library
  public categorySuggestedId; //Suggested Apps
  public dataCategory: any = [];
  public categoryFeatured: CategoryModel = undefined;
  public categorySpecial: CategoryModel = undefined;
  public categoryMostPurchased: CategoryModel = undefined;
  public categoryPurchased: CategoryModel = undefined;
  public categorySuggested: CategoryModel = undefined;
  public criteria: SortCriteria;
  skeletonTopSelling = Array(10).fill(0);
  skeletonFeatured = Array(4).fill(0);
  skeletonCategories = Array(6).fill(0);
  arrayApplicationFeatured: any = [];
  arraySubscriptions: Subscription[] = [];
  showFeatured: boolean;

  constructor(
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private applicationsService: ApplicationsService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private readonly router: Router,
    private readonly authService: AuthService,
    public utilsService: UtilsService
  ) {
    // For pipe sort
    this.criteria = {
      property: 'name',
      descending: false
    };
  }

  ngOnInit() {
    this.isLoading = true;
    this.initCategories();
    this.initApplications();
    this.initDesktop();
    this.initWidth();
    this.initCategoryMostPurchased();
    this.initCategoryFeatured();
    this.initCategoryPurchased();
    this.initCategorySpecial();
    this.storageService.resetBread();
    this.storageService.setBread('home', 'home', 'Home', undefined);
  }

  initCategories() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getCategoryList().subscribe({
        next: (categories) => {
          this.dataCategory = [];
          this.dataCategory = categories;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initApplications() {
    this.arraySubscriptions.push(
      this.applicationsService.getApplicationsSearch('', 0, 100).subscribe({
        next: (data) => {
          this.applicationsList = [];
          this.applicationsList = data['applicationList'];
          this.utilsService.addTagsList(this.applicationsList);
          this.updateCategoriesList();
        },
        error: (error) => {
          console.error(error);
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
          if (this.authService.isLoggedIn()) {
            this.router.navigate(['home']);
          } else {
            this.authService.closeSession();
          }
        }
      })
    );
  }

  updateCategoriesList() {
    if (
      this.dataCategory !== undefined &&
      this.dataCategory.length > 0 &&
      this.applicationsList !== undefined &&
      this.applicationsList.length > 0
    ) {
      let _dataCategory = cloneDeep(this.dataCategory);
      let _category = [];
      _dataCategory.forEach((category, index) => {
        let _applications = [];
        _category.push(category);
        _category[index].applications.forEach((application) => {
          //Actualizamos las apps de categorias (vienen sin precio) con las del listado de apps
          let _app = this.applicationsList.filter((app) => app._id === application._id);
          if (_app.length > 0) {
            _applications.push(_app[0]);
          }
        });
        _category[index].applications = _applications;
      });
      this.dataCategory = _dataCategory;
      this.categoriesStoreService.setCategoryList(this.dataCategory);
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  initDesktop() {
    this.arraySubscriptions.push(
      this.resizeWindowService.isDesktop$.subscribe((value: boolean) => {
        this.isDesktop = value;
      })
    );
  }

  initWidth() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value: number) => {
          this.width = value;
          this.resizeWindowService.waitForFinalEvent(
            () => {
              this.goTop();
            },
            500,
            'loadingHome'
          );
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryPurchased() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getPurchasedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryPurchased = category;
          this.categoryPurchasedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryMostPurchased() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getMostPurchasedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryMostPurchased = category;
          this.categoryMostPurchasedId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategorySpecial() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSpecialCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categorySpecial = category;
          this.categorySpecialId = category._id;
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryFeatured() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getFeaturedCategory().subscribe({
        next: (category: CategoryModel) => {
          this.categoryFeatured = category;
          this.categoryFeaturedId = category._id;
          if (category.applications?.length > 0) {
            this.showFeatured = true;
            if (this.categoryFeatured.applications.length > 4) {
              this.randomApp();
            } else {
              this.arrayApplicationFeatured = this.categoryFeatured.applications;
            }
          } else {
            this.showFeatured = false;
          }
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.goTop();
  }

  onClickMoreButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['application-detail', id]);
  }

  clickCategorySeeAll(id: string): void {
    this.storageService.setCategoryId('category-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['category-detail', id]);
  }

  goTop() {
    //Para que el scroll esté al inicio de la página
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }

  identify(index, item) {
    return index;
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    this.utilsService.randomNumber(this.categoryFeatured.applications, numberRandom, 4);
    numberRandom.forEach((idx) => {
      this.arrayApplicationFeatured.push(this.categoryFeatured.applications[idx]);
    });
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}