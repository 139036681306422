import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel,
  CommonLabels
} from 'app/common/models/common.model';
import { commonLabels } from 'app/common/common.labels';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { StorageService } from '../../../common/services/storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UtilsService } from '../../../common/services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})
export class CategoryDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() img: string;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() buttonUrl: string;
  @Input() theme: string;
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() context: string;
  @Input() isLoading: boolean;
  @Input() showText: boolean;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySuggestedId: string;
  @Input() categorySpecialId: string;
  @Input() dataCategory: CategoryModel;
  @Input() categoryName: string;
  @Input() itemsList: ApplicationModel[] | CategoryApplicationModel[];
  @Input() categoryFeatured: CategoryModel;
  @Input() categorySuggested: CategoryModel;
  @Input() categorySpecial: CategoryModel;
  @Input() categoryPurchased: CategoryModel;
  @Input() categoryMostPurchased: CategoryModel;
  @Input() arrayApplicationFeatured: any = [];
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() refreshDataEmitter = new EventEmitter<void>();
  className = 'app-category-detail';
  labels: CommonLabels = commonLabels;

  constructor(
    private readonly router: Router,
    private storageService: StorageService,
    private scrollDispatcher: ScrollDispatcher,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.goTop();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.goTop();
  }

  ngAfterViewInit(): void {
    this.goTop();
  }

  goTop() {
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }

  closeMenu($event): void {
    this.closeMenuEmitter.emit({ action: $event.action, event: $event.event });
  }

  refreshData() {
    this.refreshDataEmitter.emit();
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy(): void {}
}