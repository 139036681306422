import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { navigationAdmin, navigationUser } from 'app/navigation/navigation';
import { CategoryModel, CommonLabels } from '../../../../common/models/common.model';
import { commonLabels } from '../../../../common/common.labels';
import { StorageService } from '../../../../common/services/storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { UtilsService } from '../../../../common/services/utils.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = false;
  @Input() isAdmin? = false;
  @Input() dataCategories: CategoryModel[] = [];
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeSessionEmitter: EventEmitter<any> = new EventEmitter();

  className = 'menu';
  labels: CommonLabels = commonLabels;
  menuFixed = [];
  menuDinamic = [];
  contentMenu = [];
  option = {
    borderTop: false,
    categoryType: 0,
    externalUrl: false,
    icon: '',
    id: '',
    order: 0,
    title: '',
    type: '',
    url: ''
  };

  constructor(
    private storageService: StorageService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.formatMenu();
  }

  formatMenu() {
    if (this.isAdmin) {
      this.menuFixed = navigationAdmin;
    } else {
      this.menuFixed = navigationUser;
    }

    this.menuDinamic = [];

    this.dataCategories.forEach((category) => {
      this.option = {
        borderTop: false,
        categoryType: category.type,
        externalUrl: false,
        icon: category.icon,
        id: category._id,
        order: category.order,
        title: category.name,
        type: 'item',
        url: 'category-detail/' + category._id
      };

      this.menuDinamic.push(this.option);
    });
    this.menuDinamic.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    this.contentMenu = !this.isAdmin ? this.menuFixed.concat(this.menuDinamic) : this.menuFixed;
  }

  closeMenu($event, id): void {
    if (this.storageService.getCategoryId() !== id) {
      this.storageService.setCategoryId(id);
      this.closeMenuEmitter.emit({ action: 'refresh', event: $event });
    } else {
      this.closeMenuEmitter.emit({ action: 'refresh', event: $event });
    }
  }

  closeSession(): void {
    this.closeSessionEmitter.emit('closeSession');
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy(): void {}
}