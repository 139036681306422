import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss']
})
export class SkeletonCardComponent {
  @Input() type: string = 'vertical';
  @Input() width: number = 0;
  @Input() size: string = 'big';
  className = 'skeleton-card';
  theme: string = 'marketplace-default';
}