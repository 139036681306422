<div #scrollPage class="{{ theme }} {{ className }}">
  @if (dataCategoryId) {
    <app-category-detail
      (closeMenuEmitter)="closeMenu($event)"
      (refreshDataEmitter)="refreshData()"
      [buttonText]="buttonText"
      [buttonUrl]="buttonUrl"
      [dataCategory]="dataCategory"
      [categoryFeaturedId]="categoryFeaturedId"
      [categoryFeatured]="categoryFeatured"
      [categoryMostPurchasedId]="categoryMostPurchasedId"
      [categoryMostPurchased]="categoryMostPurchased"
      [categoryName]="this.dataCategory['name']"
      [categoryPurchasedId]="categoryPurchasedId"
      [categoryPurchased]="categoryPurchased"
      [categorySpecialId]="categorySpecialId"
      [categorySpecial]="categorySpecial"
      [categorySuggestedId]="categorySuggestedId"
      [categorySuggested]="categorySuggested"
      [context]="'category-' + this.dataCategoryId"
      [description]="description"
      [img]="img"
      [isDesktop]="isDesktop"
      [isLoading]="isLoading"
      [itemsList]="results"
      [showText]="showText"
      [theme]="theme"
      [title]="title"
      [width]="width">
    </app-category-detail>
  }
</div>