import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResizeWindowService } from '../../services/resize-window.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'skeleton-app-detail',
  templateUrl: './skeleton-app-detail.component.html',
  styleUrls: ['./skeleton-app-detail.component.scss']
})
export class SkeletonAppDetailComponent implements OnInit, OnDestroy {
  @Input() title2: string = '';
  className = 'skeleton-app-detail';
  theme: string = 'marketplace-default';
  width: number = 0;
  times: number[] = [];
  arraySubscriptions: Subscription[] = [];

  constructor(
    private resizeWindowService: ResizeWindowService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value: number) => {
          this.width = value;
          this.times = this.prepareSkeleton(this.width);
        },
        error: (err) => {
          console.error(err);
        }
      })
    );
  }

  prepareSkeleton(width) {
    return this.utilsService.prepareCards(width);
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}