<div class="{{ className }}">
  <div class="{{ className }}__wrapper">
    @if (isLoading || results === undefined) {
      <skeleton-cards-list
        [title1]="'Catalogue'"
        [context]="'/private/catalogue'"></skeleton-cards-list>
    }
    @if (!isLoading && results !== undefined) {
      <div class="{{ className }}__results-apps">
        <h3 class="{{ className }}__title">{{ 'Catalogue' }}</h3>
        @if (results.length > 0) {
          <cards-list
            (refreshDataEmitter)="refreshData()"
            [itemsList]="results"
            [theme]="theme"
            [isDesktop]="isDesktop"
            [width]="width"
            [categoryFeaturedId]="categoryFeaturedId"
            [categorySuggestedId]="categorySuggestedId"
            [categorySpecialId]="categorySpecialId"
            [categoryPurchasedId]="categoryPurchasedId"
            [categoryMostPurchasedId]="categoryMostPurchasedId"
            [categoryFeatured]="categoryFeatured"
            [categorySuggested]="categorySuggested"
            [categorySpecial]="categorySpecial"
            [categoryPurchased]="categoryPurchased"
            [categoryMostPurchased]="categoryMostPurchased"
            [context]="'/private/catalogue'"
            [title2]="categorySuggested['applications'].length > 0 ? 'Suggested Apps' : ''"
            [showButtons]="true"
            [showTagsList]="true"
            [showText]="showText"></cards-list>
        } @else {
          <app-empty
            [img]="img"
            [title]="title"
            [description]="description"
            [buttonText]="buttonText"
            [buttonUrl]="buttonUrl"
            [theme]="theme"
            [isDesktop]="isDesktop"
            [width]="width"
            [categoryFeaturedId]="categoryFeaturedId"
            [categorySuggestedId]="categorySuggestedId"
            [categorySpecialId]="categorySpecialId"
            [categoryPurchasedId]="categoryPurchasedId"
            [categoryMostPurchasedId]="categoryMostPurchasedId"
            [categoryFeatured]="categoryFeatured"
            [categorySuggested]="categorySuggested"
            [categorySpecial]="categorySpecial"
            [categoryPurchased]="categoryPurchased"
            [categoryMostPurchased]="categoryMostPurchased"
            [context]="'/private/catalogue'"
            [title2]="categorySuggested['applications'].length > 0 ? 'Suggested Apps' : ''"
            [showButtons]="true"
            [showTagsList]="true"
            [showText]="showText"></app-empty>
        }
      </div>
    }
  </div>
</div>