import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels, UserProfile } from 'app/common/models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../services/snackMessage.service';
import { UtilsService } from '../../services/utils.service';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { KeycloakService } from 'keycloak-angular';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from '../../services/applications.service';
import { CategoriesService } from '../../services/categories.service';
import { cloneDeep } from 'lodash';

@AutoUnsubscribe()
@Component({
  selector: 'app-card-vertical',
  templateUrl: './card-vertical.component.html',
  styleUrls: ['./card-vertical.component.scss']
})
export class CardVerticalComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categorySuggestedId: string;
  @Input() categoryPurchased?: CategoryModel;
  @Input() categoryFeatured?: CategoryModel;
  @Input() categorySpecial?: CategoryModel;
  @Input() categoryMostPurchased?: CategoryModel;
  @Input() categorySuggested?: CategoryModel;
  @Input() showText?: boolean;
  @Input() context?: string;
  @Input() dataCategory?: CategoryModel;
  @Input() _data: any;
  @Input() showButtons?: boolean = true;
  @Input() showTagsList?: boolean = true;
  @Output() moreButtonEvent = new EventEmitter<{ id: string; name: string; event: object }>();
  @Output() refreshDataEmitter = new EventEmitter<void>();
  className = 'app-card-vertical';
  labels: CommonLabels = commonLabels;
  purchased: boolean = false;
  special: boolean = false;
  featured: boolean = false;
  menuState: string = '';
  shortDescriptionTooltip: string;
  disabledButton = false;
  showAddToLibraryButton = false;
  showContactButton = false;
  showAlreadyInLibraryButton = false;
  typeModal: string = 'Paid';
  userProfile: UserProfile;
  categoriesList: CategoryModel[];
  applicationsList: any = [];
  categoriesTags: any = [];

  constructor(
    public matDialog: MatDialog,
    private snackMessageService: SnackMessageService,
    private categoriesStoreService: CategoriesStoreService,
    private categoriesService: CategoriesService,
    private applicationsService: ApplicationsService,
    private readonly keycloak: KeycloakService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    if (this.showButtons) {
      this.configButtons(this._data);
    }
    this.checkCategoriesTags();
  }

  checkCategoriesTags() {
    this.categoriesTags = [];
    let _categoryBarbara = this._data.categories.find((cat) => cat['type'] === 4);
    let _categoryStandard = this._data.categories.find((cat) => cat['type'] === 0);
    if (_categoryBarbara !== undefined) {
      this.categoriesTags.push(_categoryBarbara);
    }
    if (_categoryStandard !== undefined) {
      this.categoriesTags.push(_categoryStandard);
    }
  }

  initSubscriptions(): void {
    this.categoriesStoreService.getStateMenu().subscribe({
      next: (state: string) => {
        this.menuState = state;
        this.shortDescriptionTooltip = this._data.shortDescription.replace(/<[^>]*>/g, '');
      },
      error: (error) => {
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  clickButtonMore(id: string, name: string, event: object): void {
    this.moreButtonEvent.emit({ id, name, event });
  }

  configButtons(dataApp): void {
    switch (dataApp.type) {
      case 0:
        this.disabledButton = dataApp.versions === undefined || dataApp.versions.length === 0;
        this.showContactButton = false;
        this.showAddToLibraryButton = true;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'Version';
        break;
      case 1:
        this.disabledButton = false;
        this.showContactButton = false;
        this.showAddToLibraryButton = true;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'Terms';
        break;
      case 2:
        this.disabledButton = false;
        this.showContactButton = true;
        this.showAddToLibraryButton = false;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'contact-form-public';
        break;
      default:
        this.disabledButton = true;
        this.showContactButton = false;
        this.showAddToLibraryButton = false;
        this.showAlreadyInLibraryButton = false;
        this.typeModal = 'Paid';
        break;
    }
    if (
      dataApp.categories.find(
        (category: CategoryModel) => category._id === this.categoryPurchasedId
      )
    ) {
      this.purchased = true;
      this.disabledButton = true;
      this.showContactButton = false;
      this.showAddToLibraryButton = false;
      this.showAlreadyInLibraryButton = true;
    }
  }

  addToLibrary(id: string, typeModal: string): void {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: id,
        width: this.width,
        type: typeModal,
        originalData: this._data,
        imgHeader: this._data.icon,
        title: this._data.name,
        textHeader: this._data.shortDescription,
        terms: this.utilsService.conversorString(this._data.termsAndConditions),
        showFooter: true,
        showButtonClose: true,
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action !== 'close') {
        switch (result.action) {
          case 'contact-form-public':
            this.contact(result);
            break;
          case 'startTrial':
            this.createDeal(result.element);
            this.addToLibraryService(result.element._id);
            break;
          case 'addToPanel':
            this.addToLibraryService(result.element._id);
            break;
        }
      } else {
        this.disabledButton = false;
      }
    });
  }

  addToLibraryService(id: string) {
    this.applicationsService.addToLibrary(id).subscribe({
      next: () => {
        this.prepareDataCategories();
        this.refreshDataEmitter.emit();
      },
      error: (error) => {
        //this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  prepareDataCategories() {
    this.categoriesService.getAllCategories().subscribe({
      next: (categories: CategoryModel[]) => {
        this.categoriesList = [];
        this.categoriesList = categories;
      },
      error: (error) => {
        console.error(error);
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
    this.applicationsService.getApplicationsSearch('', 0, 100).subscribe({
      next: (data) => {
        this.applicationsList = [];
        this.applicationsList = data['applicationList'];
        this.utilsService.addTagsList(this.applicationsList);
        this.updateCategoriesList();
      },
      error: (error) => {
        console.error(error);
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  updateCategoriesList() {
    if (
      this.categoriesList !== undefined &&
      this.categoriesList.length > 0 &&
      this.applicationsList !== undefined &&
      this.applicationsList.length > 0
    ) {
      let _dataCategory = cloneDeep(this.categoriesList);
      let _category = [];
      _dataCategory.forEach((category, index) => {
        let _applications = [];
        _category.push(category);
        _category[index].applications.forEach((application) => {
          //Actualizamos las apps de categorias (vienen sin precio) con las del listado de apps
          let _app = this.applicationsList.filter((app) => app._id === application._id);
          if (_app.length > 0) {
            _applications.push(_app[0]);
          }
        });
        _category[index].applications = _applications;
      });
      this.categoriesList = _dataCategory;
      this.categoriesStoreService.setCategoryList(this.categoriesList);
    }
  }

  async createDeal(dataApp) {
    this.userProfile = await this.keycloak.loadUserProfile();
    const tokenKeycloak = await this.keycloak.getToken();
    const dataCompany = jwt_decode(tokenKeycloak)['companyName'];
    const json = {
      properties: [
        {
          value: `Marketplace App Purchase - ${dataApp.name} - ${dataCompany}`,
          name: 'dealname'
        },
        {
          value: 'default',
          name: 'pipeline'
        },
        { value: 'appointmentscheduled', name: 'dealstage' },
        {
          value: 'MARKETPLACE',
          name: 'origen_del_negocio'
        },
        {
          value:
            `The user ${this.userProfile.firstName} ${this.userProfile.lastName} has downloaded the ` +
            `${dataApp.name} app (app ID = ${dataApp._id}) from Barbara Marketplace. Before the trial is finished, ` +
            `the user must be contacted to confirm the purchase. His/her contact details are: ${this.userProfile.email} ` +
            `and ${this.userProfile.attributes.phone[0]}`,
          name: 'description'
        }
      ]
    };
    this.applicationsService.createDeal(json).subscribe({
      error: (error) => {
        console.log(error);
      }
    });
  }

  createDealnoBarbaraApp(dataApp, dataUser) {
    let json;
    if (dataApp.type === 1) {
      json = {
        properties: [
          {
            value: `Marketplace App Purchase Request - ${dataApp.name} - ${dataUser.company}`,
            name: 'dealname'
          },
          {
            value: 'default',
            name: 'pipeline'
          },
          { value: 'appointmentscheduled', name: 'dealstage' },
          {
            value: 'MARKETPLACE',
            name: 'origen_del_negocio'
          },
          {
            value:
              `The user ${dataUser.firstname} ${dataUser.secondname} has requested the purchase of ${dataApp.name} ` +
              `app (app ID = ${dataApp._id}) in Barbara Marketplace. The user must be contacted ASAP. His/her contact ` +
              `details are: ${dataUser.email} and ${dataUser.phone}`,
            name: 'description'
          }
        ]
      };
    } else {
      json = {
        properties: [
          {
            value: `Marketplace Service Info Request - ${dataApp.name} - ${dataUser.company}`,
            name: 'dealname'
          },
          {
            value: 'default',
            name: 'pipeline'
          },
          { value: 'appointmentscheduled', name: 'dealstage' },
          {
            value: 'MARKETPLACE',
            name: 'origen_del_negocio'
          },
          {
            value:
              `The user ${dataUser.firstname} ${dataUser.secondname} has requested information about the services ` +
              `provided by ${dataApp.name} app (app ID = ${dataApp._id}) through Barbara Marketplace. The user must ` +
              `be contacted ASAP. His/her contact details are: ${dataUser.email} and ${dataUser.phone}`,
            name: 'description'
          }
        ]
      };
    }

    this.applicationsService.createDeal(json).subscribe({
      error: (error) => {
        console.log(error);
      }
    });
  }

  contact(id: string): void {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: id,
        width: this.width,
        type: 'contact-form-public',
        imgHeader: 'assets/images/logos/market_avatar.svg',
        title: 'Contact us',
        textHeader:
          'Please fill out the form below and we will get back to you as soon as possible.',
        terms: '',
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            buttonId: 'close-modal',
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.action !== 'close') {
        this.createDealnoBarbaraApp(this._data, result);
      }
    });
  }

  ngOnDestroy(): void {}
}
