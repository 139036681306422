<mat-toolbar (keydown.escape)="closeMenu()" class="{{ theme }} {{ className }}__header">
  <div class="{{ theme }} {{ className }}__header-container-left">
    <div class="{{ theme }} {{ className }}__header-toggle-menu">
      <mat-icon
        (click)="openMenu()"
        *ngIf="!showMenu"
        class="material-icons-outlined"
        id="show-main-menu"
        matTooltip="{{ labels.showMenu }}"
        matTooltipClass="{{ theme }} custom-tooltip"
        >menu
      </mat-icon>
      <mat-icon
        (click)="closeMenu()"
        *ngIf="showMenu"
        class="material-icons-outlined"
        id="hide-main-menu"
        matTooltip="{{ labels.hideMenu }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        close
      </mat-icon>
    </div>
    <a class="{{ theme }} {{ className }}__header-logo" routerLink="public/_home">
      <h1 class="hidden">Barbaraiot MarketPlace</h1>
      <div class="{{ theme }} {{ className }}__header-logo-wrapper" id="header-logo-public">
        <img
          alt="Icono marketplace"
          class="{{ theme }} {{ className }}__header-logo-icon"
          src="{{
            isDesktop
              ? 'assets/images/logos/ecosystem_market.svg'
              : 'assets/images/logos/market_avatar.svg'
          }}" />
      </div>
    </a>
  </div>
  <div *ngIf="false" class="{{ theme }} {{ className }}__header-container-center"></div>
  <div class="{{ theme }} {{ className }}__header-container-right">
    <div class="{{ className }}__container-ecosystem">
      <mat-icon
        (click)="openMenuEcosystem()"
        *ngIf="!showMenuEcosystem"
        class="{{ className }}__icon-ecosystem"
        id="show-ecosystem-menu"
        matTooltip="{{ labels.showEcosystem }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        dialpad
      </mat-icon>
      <mat-icon
        (click)="closeMenuEcosystem()"
        *ngIf="showMenuEcosystem"
        class="{{ className }}__icon-ecosystem-close"
        id="hide-ecosystem-menu"
        matTooltip="{{ labels.hideEcosystem }}"
        matTooltipClass="{{ theme }} custom-tooltip">
        close
      </mat-icon>
      <ng-container *ngIf="showMenuEcosystem">
        <div class="{{ className }}__eco-container">
          <ul class="{{ className }}__menu-eco">
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openWindow('https://barbara.tech', 'barbara')"
                class="{{ className }}__menu-eco-link"
                id="barbara-link"
                rel="noopener">
                <img
                  alt="ico barbara"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_barbara.svg" />
              </a>
            </li>
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openWindow(environment.panelUrl, 'barbaraPanel')"
                class="{{ className }}__menu-eco-link"
                id="panel-link"
                rel="noopener">
                <img
                  alt="ico panel"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_panel.svg" />
              </a>
            </li>
            <li *ngIf="false" class="{{ className }}__menu-eco-item">
              <a
                (click)="openWindow(environment.marketUrl, 'barbaraMarket')"
                class="{{ className }}__menu-eco-link"
                id="market-link"
                rel="noopener">
                <img
                  alt="ico market"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_market.svg" />
              </a>
            </li>
            <li class="{{ className }}__menu-eco-item">
              <a
                (click)="openWindow('https://academy.barbaraiot.com/', 'barbaraAcademy')"
                class="{{ className }}__menu-eco-link"
                id="academy-link"
                rel="noopener">
                <img
                  alt="ico academy"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_academy.svg" />
              </a>
            </li>

            <li *ngIf="false" class="{{ className }}__menu-eco-item">
              <a
                (click)="openWindow('https://studio.barbaraiot.com/', 'barbaraStudio')"
                class="{{ className }}__menu-eco-link"
                id="studio-link"
                rel="noopener">
                <img
                  alt="ico studio"
                  class="{{ className }}__menu-eco-icon"
                  src="assets/images/logos/ecosystem_studio.svg" />
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <a
      (click)="login()"
      class="{{ theme }} {{ className }}__header-container-right-link"
      id="{{ className }}__login-text">
      <div *ngIf="isDesktop" class="{{ className }}__header-container-right-name">
        {{ labels.login }}
      </div>
    </a>

    <mat-icon
      (click)="login()"
      class="material-icons-outlined icon-logout"
      id="{{ className }}__login-icon"
      matTooltip="{{ labels.login }}"
      matTooltipClass="{{ theme }} custom-tooltip">
      login
    </mat-icon>
  </div>
</mat-toolbar>