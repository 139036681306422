import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackMessageService {
  constructor(private snackBar: MatSnackBar) {}

  sendMessage(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: 4000,
      panelClass: 'app-notification-success'
    });
  }

  readError(error: any): void {
    const errorCode = error.error.response.errorCode;

    if (
      errorCode === 'E113' ||
      errorCode === 'E114' ||
      errorCode === 'E115' ||
      errorCode === 'E063' ||
      errorCode === 'E104' ||
      errorCode === 'E103' ||
      errorCode === 'E077'
    ) {
      return;
    }

    const errorMessage = error.error.response.errorMessage;
    this.sendMessage(errorMessage);
  }
}