import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationsService } from '../../../common/services/applications.service';
import { StorageService } from '../../../common/services/storage.service';
import { ApplicationsSearchObservableService } from '../../../common/services/applications-search-observable.service';
import { Subscription } from 'rxjs';
import { ResizeWindowService } from '../../../common/services/resize-window.service';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel
} from '../../../common/models/common.model';
import { CategoriesStoreService } from '../../../common/services/category-observable.service';
import { Router } from '@angular/router';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { AuthService } from '../../../common/services/auth.service';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent implements OnInit, OnDestroy {
  className = 'result-page';
  theme = 'marketplace-default';
  searchInput: string;
  results: ApplicationModel[] | CategoryApplicationModel[];
  isLoading = true;
  isDesktop: boolean;
  width: number;
  categoryFeaturedId: string;
  categorySpecialId: string;
  categoryPurchasedId: string;
  categoryMostPurchasedId: string;
  categorySuggestedId: string;
  categoryFeatured: CategoryModel;
  categorySpecial: CategoryModel;
  categoryPurchased: CategoryModel;
  categoryMostPurchased: CategoryModel;
  categorySuggested: CategoryModel;
  showText: boolean = true;
  img = 'empty-search';
  title = 'No matched results';
  description = 'Check out the suggestions below or visit our catalogue to discover new apps.';
  buttonText = 'Go to Home page';
  buttonUrl = '/home';
  arraySubscriptions: Subscription[] = [];

  constructor(
    private applicationsService: ApplicationsService,
    private categoriesStoreService: CategoriesStoreService,
    private applicationsSearchObservableService: ApplicationsSearchObservableService,
    private readonly resizeWindowService: ResizeWindowService,
    private storageService: StorageService,
    private snackMessageService: SnackMessageService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.initSubscriptions();
    this.storageService.resetBread();
    this.storageService.setBread('home', 'home', 'Home', undefined);
  }

  initSubscriptions() {
    this.isLoading = true;
    this.initDesktop();
    this.initWidth();
    this.initCategoryFeatured();
    this.initSpecialCategory();
    this.initPurchasedCategory();
    this.initMostPurchasedCategory();
    this.initCategorySuggested();
    this.initSearch();
  }

  initSearch() {
    this.arraySubscriptions.push(
      this.applicationsSearchObservableService.getSearchValue().subscribe({
        next: (data) => {
          this.resizeWindowService.waitForFinalEvent(
            () => {
              this.searchInput = data;
              this.search(this.searchInput);
            },
            500,
            'loadingResults'
          );
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
          if (this.authService.isLoggedIn()) {
            this.router.navigate(['home']);
          } else {
            this.authService.closeSession();
          }
        }
      })
    );
  }

  search(searchInput: string) {
    this.arraySubscriptions.push(
      this.applicationsService.getApplicationsSearch(searchInput, 0, 100).subscribe({
        next: (data: ApplicationModel[] | CategoryApplicationModel[]) => {
          this.results = data['applicationList'];
          this.addTagsList(this.results);
        },
        error: (error) => {
          console.error(error);
        }
      })
    );
  }

  addTagsList(appsList) {
    let _appsList = [];
    appsList.forEach((app, index) => {
      _appsList.push(app);
      let _dataTagsList = [];
      if (app.categories.find((category) => category.type === 2) !== undefined) {
        if (!_dataTagsList.includes('In library')) {
          _dataTagsList.push('In library');
        }
      }
      if (app.categories.find((category) => category.type === 3) !== undefined) {
        if (!_dataTagsList.includes('Best Seller')) {
          _dataTagsList.push('Best Seller');
        }
      }
      if (
        app.categories.find((category) => category.type === 4) !== undefined &&
        app.price[0].value === 0
      ) {
        if (!_dataTagsList.includes('Free')) {
          _dataTagsList.push('Free');
        }
      }
      _appsList[index].dataTagList = _dataTagsList;
    });
    this.isLoading = false;
    return _appsList;
  }

  initDesktop() {
    this.arraySubscriptions.push(
      this.resizeWindowService.isDesktop$.subscribe({
        next: (value) => {
          this.isDesktop = value;
        }
      })
    );
  }

  initWidth() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value) => {
          this.width = value;
        }
      })
    );
  }

  initCategoryFeatured() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getFeaturedCategory().subscribe({
        next: (category) => {
          this.categoryFeatured = category;
          this.categoryFeaturedId = category._id;
        }
      })
    );
  }

  initCategorySuggested() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSuggestedCategory().subscribe({
        next: (category) => {
          this.categorySuggested = category;
          this.categorySuggestedId = category._id;
        }
      })
    );
  }

  initSpecialCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSpecialCategory().subscribe({
        next: (category) => {
          this.categorySpecial = category;
          this.categorySpecialId = category._id;
        }
      })
    );
  }

  initPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getPurchasedCategory().subscribe({
        next: (category) => {
          this.categoryPurchased = category;
          this.categoryPurchasedId = category._id;
        }
      })
    );
  }

  initMostPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getMostPurchasedCategory().subscribe({
        next: (category) => {
          this.categoryMostPurchased = category;
          this.categoryMostPurchasedId = category._id;
        }
      })
    );
  }

  navigateHome() {
    this.router.navigate(['/home']);
  }

  refreshData() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.storageService.removeStoreSearch();
    this.applicationsSearchObservableService.setSearchValue('');
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}