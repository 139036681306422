<div class="{{ theme }} {{ className }}__filter-wrapper">
  <div class="{{ className }}__filter {{ classFilter }}">
    <input
      #search
      (blur)="removeClassFocus()"
      (focus)="addClassFocus()"
      (keyup)="onInputFilter($event)"
      [(ngModel)]="searchInput"
      autocomplete="off"
      class="{{ className }}__input"
      id="{{ className }}_input-filter-search"
      placeholder="{{ placeholder }}"
      type="search" />
    <mat-icon class="material-symbols-outlined prefix">search</mat-icon>
    <button
      (click)="
        searchInput = '';
        onClearFilter({
          clear: true
        })
      "
      *ngIf="searchInput"
      class="small suffix"
      id="{{ className }}_button-clear"
      mat-icon-button
      matSuffix>
      <mat-icon class="material-symbols-outlined">close</mat-icon>
    </button>
  </div>
</div>