<!--<div id="hubspotFormContactUs" ></div>-->
<mat-dialog-content class="{{ className }}__body">
  <div *ngIf="objectForm" class="{{ className }}__form">
    <form [formGroup]="contactUs" novalidate>
      <div *ngFor="let input of objectForm" class="{{ className }}__form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ input.placeholder }}</mat-label>
          <input
            [autocomplete]="input.autocomplete"
            [formControlName]="input.formControl"
            [id]="input.id"
            [maxlength]="input.maxLength"
            [name]="input.name"
            [placeholder]="input.placeholder"
            [required]="input.required"
            matInput />
          <mat-error *ngIf="contactUs.get(input.formControl).hasError('required')">
            {{ input.placeholder }} is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="{{ className }}__form-field-checkbox-container">
        <mat-checkbox
          [checked]="contactUs.get('communications').value"
          [id]="'communications'"
          [value]="contactUs.get('communications').value"
          color="purple"
          formControlName="communications"
          labelPosition="after">
          <p>I agree to receive communications from Barbara</p>
        </mat-checkbox>
      </div>
      <div class="{{ className }}__form-field-checkbox-container">
        <mat-checkbox
          [checked]="false"
          [id]="'conditions'"
          [value]="contactUs.get('conditions').value"
          color="purple"
          formControlName="conditions"
          labelPosition="after"
          required>
          <p>
            I have read and accept the
            <a
              class="enlace"
              href="https://barbaraiot.com/terms-and-conditions"
              rel="noopener"
              target="_blank">
              Terms and Conditions
            </a>
            and the
            <a
              class="enlace"
              href="https://barbaraiot.com/en/politica-de-privacidad/"
              rel="noopener"
              target="_blank">
              Privacy Policy*
            </a>
          </p>
        </mat-checkbox>
        <!--
        <mat-error *ngIf="contactUs.get('conditions').hasError('required')">
          You must accept the Terms and Conditions and the Privacy Policy
        </mat-error>
        -->
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="{{ theme }} {{ className }}__footer">
  <div class="{{ theme }} {{ className }}__submit">
    <button
      (click)="submitForm()"
      [disabled]="!contactUs.valid"
      class="big"
      color="accent"
      id="submit"
      mat-raised-button>
      Submit
    </button>
  </div>
</mat-dialog-actions>