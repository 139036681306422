<div class="{{ className }}">
  <div class="{{ className }}__category">
    <ul class="{{ theme }} {{ className }}__category-list">
      @for (item of itemsList; track $index) {
        <li class="{{ theme }} {{ className }}__category-list-item col-{{ times.length }}">
          <app-card-vertical
            (refreshDataEmitter)="refreshData()"
            [theme]="theme"
            [isDesktop]="isDesktop"
            [width]="width"
            [categoryFeaturedId]="categoryFeaturedId"
            [categorySuggestedId]="categorySuggestedId"
            [categorySpecialId]="categorySpecialId"
            [categoryPurchasedId]="categoryPurchasedId"
            [categoryMostPurchasedId]="categoryMostPurchasedId"
            [categoryFeatured]="categoryFeatured"
            [categorySuggested]="categorySuggested"
            [categorySpecial]="categorySpecial"
            [categoryPurchased]="categoryPurchased"
            [categoryMostPurchased]="categoryMostPurchased"
            [showText]="showText"
            [showButtons]="showButtons"
            [showTagsList]="showTagsList"
            [context]="context"
            (moreButtonEvent)="onClickMoreButton(item._id)"
            [_data]="item">
          </app-card-vertical>
        </li>
      }
    </ul>
  </div>
  @if (context !== '/private/catalogue' && arrayApplicationFeatured.length > 0) {
    <div class="{{ className }}__category-suggested">
      <h3 class="{{ className }}__category-suggested-title">
        {{ title2 }}
      </h3>
      <ul class="{{ theme }} {{ className }}__category-list suggested">
        @for (item of arrayApplicationFeatured; track $index) {
          @if (times.length >= $index) {
            <li
              class="{{ theme }} {{ className }}__category-list-item col-{{
                times.length
              }} suggested">
              <app-card-horizontal
                [theme]="theme"
                [isDesktop]="isDesktop"
                [width]="width"
                [categoryFeaturedId]="categoryFeaturedId"
                [categorySuggestedId]="categorySuggestedId"
                [categorySpecialId]="categorySpecialId"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryFeatured]="categoryFeatured"
                [categorySuggested]="categorySuggested"
                [categorySpecial]="categorySpecial"
                [categoryPurchased]="categoryPurchased"
                [categoryMostPurchased]="categoryMostPurchased"
                [showText]="showText"
                [showButtons]="showButtons"
                [showTagsList]="showTagsList"
                [context]="context"
                (moreButtonEvent)="onClickMoreHorizontalButton(item._id)"
                [_data]="item">
              </app-card-horizontal>
            </li>
          }
        }
      </ul>
    </div>
  }
</div>