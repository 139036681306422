import { ChangeImageModalLabels, CommonLabels, PictureModalLabels } from './models/common.model';

export const commonLabels: CommonLabels = {
  academy: 'Barbara Academy',
  account: 'Account',
  actions: 'Actions',
  activate: 'ACTIVATE',
  activateCapitalize: 'Activate',
  add: 'ADD',
  addAsset: 'ADD ASSET',
  addTag: 'Add tag',
  addToLibrary: 'Add to Library',
  afterFirstMonth: '(*) After the first month',
  afterTrial: 'After trial',
  alredyInLibrary: 'Already in Library',
  app: 'App',
  applications: 'Applications',
  apps: 'Apps',
  architectures: 'Architectures',
  assets: 'ASSETS',
  auditLogs: 'Audit logs',
  back: 'BACK',
  backCapitalize: 'Back',
  barbaraBasics: 'Barbara Basics',
  basics: 'Basics',
  cancel: 'Cancel',
  categories: 'Categories',
  category: 'Category',
  changeImage: 'CHANGE IMAGE',
  changeLog: 'ChangeLog',
  cleaningData: 'CLEANING DATA',
  close: 'Close',
  collapse: 'Collapse',
  comingSoon: 'Coming Soon',
  config: 'Config',
  contact: 'Contact',
  contactSales: 'Contact Sales',
  contactUs: 'Contact Us',
  copy: 'Copy',
  createDevice: 'Create device',
  custom: 'Custom',
  dashboard: 'Dashboard',
  date: 'Date',
  deactivate: 'DEACTIVATE',
  delete: 'Delete',
  deleting: 'DELETING',
  deletingApps: 'DELETING APPLICATION',
  description: 'Description',
  details: 'Details',
  device: 'device',
  devices: 'Devices',
  deviceslowercase: 'devices',
  docs: 'Docs',
  download: 'Download',
  edit: 'Edit',
  editConfig: 'Edit config',
  editVersion: 'Edit version',
  expand: 'Expand',
  exportCSV: 'Export to CSV',
  featured: 'Featured',
  suggested: 'Suggested',
  filter: 'Filter',
  follow: 'Follow',
  free: 'Free',
  group: 'Group',
  groups: 'Groups',
  help: 'Help',
  hide: 'Hide',
  hideEcosystem: 'Hide Ecosystem',
  hideMenu: 'Hide menu',
  hideMenuUser: 'Hide user menu',
  home: 'Home',
  installedOn: 'Installed on ',
  lastSeen: 'Last Seen',
  lastVersion: 'Last version',
  launchBatch: 'launch batch',
  less: 'Less',
  login: 'Login',
  logout: 'Logout',
  map: 'Map',
  mb: 'MB',
  mbCapitalize: 'Mb',
  modalDeleteApplicationBodyText: 'This action cannot be undone.',
  modalDeleteApplicationHeaderText:
    'The application, its versions and all related data will be deleted from marketplace',
  modalDeleteApplicationTitle: 'Are you sure you want to delete the application?',
  modalDeleteCategoryBodyText: 'This action cannot be undone.',
  modalDeleteCategoryHeaderText: 'The category and all its related data will be deleted.',
  modalDeleteCategoryTitle: 'Are you sure you want to delete the category?',
  modalDeleteVersionApplicationBodyText: 'This action cannot be undone.',
  modalDeleteVersionApplicationHeaderText: 'The version and all its related data will be deleted.',
  modalDeleteVersionApplicationTitle: 'Are you sure you want to delete this version?',
  modalHideApplicationBodyText: 'This action can be undone just setting is at visible again.',
  modalHideApplicationHeaderText:
    'The application, its versions and all related data will NOT be deleted from marketplace.',
  modalHideApplicationTitle: 'Are you sure you want to hide the application?',
  modalShowApplicationBodyText: 'This action can be undone just setting is at hidden again.',
  modalShowApplicationHeaderText:
    'The application, its versions and all related data will be visible again. ',
  modalShowApplicationTitle: 'Are you sure you want to set this application as visible?',
  more: 'More',
  mostUsed: 'Most used',
  name: 'Name',
  newApp: 'New app',
  newCategory: 'New category',
  newDevice: 'New device',
  newVersion: 'New version',
  noApps: 'No apps ',
  noAppsFound: 'No apps found',
  noCategoriesFound: 'No categories found',
  noDataFound: 'No data found',
  noDescriptionFound: 'No description found',
  of: 'of',
  online: 'Online',
  order: 'Order',
  osImages: 'OS Images',
  pleaseFill: 'Please fill out the form below and we will get back to you as soon as possible.',
  private: 'Private',
  public: 'Public',
  publishApp: 'Show application',
  published: 'Published',
  publisher: 'Publisher',
  purchased: 'Purchased',
  reboot: 'REBOOT',
  recentDevices: 'Recent Devices',
  recentDevicesNoData: 'No recent devices found',
  refresh: 'Refresh',
  requestInfo: 'Request info',
  restart: 'RESTART',
  running: 'Running',
  runningApp: 'RUNNING',
  save: 'Save',
  search: 'Search',
  seeAll: 'See All',
  show: 'Show',
  showEcosystem: 'Show Ecosystem',
  showMenu: 'Show menu',
  showMenuUser: 'Show user menu',
  showing: 'Showing',
  shutDown: 'SHUT DOWN',
  size: 'Size',
  special: 'Special',
  standard: 'Standard',
  startApp: 'START',
  startAppCapitalize: 'Start',
  startTrial: 'Start Trial',
  status: 'Status',
  stopApp: 'STOP',
  stopAppCapitalize: 'Stop',
  stoppedApp: 'STOPPED',
  submit: 'Submit',
  tag: 'TAG',
  tagArtificialIntelligence: 'AI',
  tagIndustrialProtocols: 'Industrial',
  tagTailorMade: 'Tailor-made',
  technicalNotes: 'Technical notes',
  termsAndConditions: 'Terms and Conditions',
  textNewDevice: 'Register new devices',
  timeStamp: 'Time',
  titleApps: 'Marketplace apps',
  titleVersions: 'List Versions',
  topSelling: 'Top selling',
  trialInProgress: 'Trial in progress',
  type: 'Type',
  upTo100Workloads: '*Up to 100 workloads',
  update: 'Update',
  updating: 'UPDATING',
  updatingCapitalize: 'Updating',
  upload: 'Upload',
  uploading: 'UPLOADING',
  users: 'Users',
  version: 'Version',
  versionIsRequiredForPublish: 'Version is required for publish',
  versions: 'Versions',
  view: 'VIEW',
  visibility: 'Visibility',
  vpn: 'VPN',
  wipeAndBrick: 'WIPE & BRICK',
  wrap: 'Wrap',
  yet: 'YET'
};
export const changeImageModalLabels: ChangeImageModalLabels = {
  titleDevicePicture: 'Node picture',
  titlePicture: 'Add image',
  titleIcon: 'Application icon',
  generateIcon: 'Generate custom icon',
  generatePicture: 'Generate custom picture',
  background: 'Background',
  color: 'Color',
  fontSize: 'Font-size',
  textPlaceholder: 'Enter text',
  cancel: commonLabels.cancel,
  delete: commonLabels.delete,
  upload: commonLabels.upload,
  generate: 'Generate'
};
export const pictureModalLabels: PictureModalLabels = {
  titleImagen: 'Crop picture',
  titleIcon: 'Crop icon',
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  cancel: commonLabels.cancel,
  upload: commonLabels.upload
};