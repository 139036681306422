<div class="{{ theme }} {{ className }}">
  <app-header-public
    (closeMenuEcosystemEmitter)="closeMenuEcosystem()"
    (closeMenuEmitter)="closeMenu()"
    (openMenuEcosystemEmitter)="openMenuEcosystem()"
    (openMenuEmitter)="openMenu()"
    (openWindowEmitter)="openWindow($event.url, $event.target)"
    [className]="className"
    [isDesktop]="isDesktop"
    [showMenuEcosystem]="showMenuEcosystem"
    [showMenu]="showMenu"
    [theme]="theme"
    [width]="width"
    class="{{ theme }} {{ className }}__header"></app-header-public>
  <mat-sidenav-container
    *ngIf="!isLoading"
    [hasBackdrop]="!isDesktop"
    [style.marginTop.px]="!isDesktop ? 59 : 0"
    class="{{ theme }} {{ className }}__container">
    <mat-sidenav
      #sidenav
      [class.main-layout__menu--active]="showMenu"
      [mode]="isDesktop ? 'push' : 'over'"
      [style.bottom]="isDesktop ? '0' : 'auto'"
      [style.top]="isDesktop ? '60px' : '59px'"
      class="{{ theme }} {{ className }}__menu"
      disableClose
      fixedInViewport="true"
      fixedTopGap="59">
      <app-menu-public
        (openModalContactEmitter)="openModalContact($event)"
        [dataCategory]="dataCategory"
        [isDesktop]="isDesktop"
        [showMenu]="showMenu"
        [theme]="theme"
        [width]="width"></app-menu-public>
    </mat-sidenav>
    <mat-sidenav-content
      [class.main-layout__content--active]="showMenu"
      class="{{ theme }} {{ className }}__content">
      <div
        (click)="showMenuEcosystem = false; showMenu = false"
        *ngIf="showMenuEcosystem"
        class="{{ theme }} {{ className }}__overlay"></div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>