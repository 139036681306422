import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InputFileComponent } from './components/input-file/input-file.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsCommonModule } from './forms-common.module';
import { MarkdownModule } from 'ngx-markdown';
import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

import { ConvertBytesPipe } from './utils/convert-bytes.pipe';
import { ReplacePipe } from './utils/replace.pipe';
import { ArchitecturePipe, ArchitecturesPipe } from './utils/architectures.pipe';
import { PersistPipe } from './utils/persist.pipe';
import { SortByPipe } from './utils/sort-by.pipe';

import { BannerComponent } from './components/banner/banner.component';
import { CardHorizontalComponent } from './components/card-horizontal/card-horizontal.component';
import { CardVerticalComponent } from './components/card-vertical/card-vertical.component';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { DevicePictureModalComponent } from './components/device-picture-modal/device-picture-modal.component';
import { DeviceChangeImageComponent } from './components/device-change-image/device-change-image.component';
import { HubspotComponent } from './components/hubspot/hubspot.component';
import { ReadmeMdComponent } from './components/readme-md/readme-md.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SafePipe } from './utils/safe.pipe';
import { CapitalizePipe } from '../pipe/capitalize.pipe';
import { CardsListComponent } from './components/cards-list/cards-list.component';
import { EmptyComponent } from './components/empty/empty.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { RouterLink } from '@angular/router';
import { SkeletonCardComponent } from './components/skeleton-card/skeleton-card.component';
import { SkeletonCardsListComponent } from './components/skeleton-cards-list/skeleton-cards-list.component';
import { SkeletonAppDetailComponent } from './components/skeleton-app-detail/skeleton-app-detail.component';

export const MY_MAT_TOOLTIP_DEFAULT_OPTIONS: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 100,
  touchendHideDelay: 100,
  disableTooltipInteractivity: true
};

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSliderModule,
    MatDialogModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsCommonModule,
    MarkdownModule.forRoot(),
    RouterLink,
    NgOptimizedImage
  ],
  declarations: [
    BannerComponent,
    ReadmeMdComponent,
    InputFileComponent,
    CardHorizontalComponent,
    CardVerticalComponent,
    ModalMessageComponent,
    CapitalizePipe,
    ConvertBytesPipe,
    DevicePictureModalComponent,
    DeviceChangeImageComponent,
    HubspotComponent,
    SearchInputComponent,
    CardsListComponent,
    EmptyComponent,
    BreadcrumbsComponent,
    SkeletonCardComponent,
    SkeletonCardsListComponent,
    SkeletonAppDetailComponent,
    ReplacePipe,
    ArchitecturesPipe,
    ArchitecturePipe,
    PersistPipe,
    SortByPipe,
    SafePipe
  ],

  exports: [
    CommonModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    CapitalizePipe,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    BannerComponent,
    CardHorizontalComponent,
    CardVerticalComponent,
    ModalMessageComponent,
    DevicePictureModalComponent,
    DeviceChangeImageComponent,
    InputFileComponent,
    ReadmeMdComponent,
    HubspotComponent,
    SearchInputComponent,
    CardsListComponent,
    EmptyComponent,
    BreadcrumbsComponent,
    SkeletonCardComponent,
    SkeletonCardsListComponent,
    SkeletonAppDetailComponent,
    ConvertBytesPipe,
    ReplacePipe,
    ArchitecturesPipe,
    ArchitecturePipe,
    PersistPipe,
    SortByPipe,
    SafePipe
  ]
})
export class SharedCommonModule {}