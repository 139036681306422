import { Injectable } from '@angular/core';
import { CategoryModel } from 'app/common/models/common.model';
import { BackEndCommunicationService } from 'app/common/services/beComm.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(private backEndCommunicationService: BackEndCommunicationService) {}

  /* PUBLIC */
  public getAllCategoriesP = (): Observable<any> => {
    return this.backEndCommunicationService.getResourceObservable('/categories/public');
  };
  public getCategoryFeaturedByType = (type: string, filter: string): Observable<CategoryModel> => {
    return this.backEndCommunicationService.getResourceObservable(
      `/categories/public/type?type=${type}&filter=${filter}`
    );
  };

  /* PRIVATE */
  public getAllCategories = (): Observable<CategoryModel[]> => {
    return this.backEndCommunicationService.getResourceObservable('/categories');
  };
  public getCategoryById = (id: string): Observable<CategoryModel> => {
    return this.backEndCommunicationService.getResourceObservable(`/categories/${id}`);
  };
}