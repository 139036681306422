import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() theme: string;
  @Input() isDesktop: boolean = true;
  @Input() width: number;
  @Input() _data: any;
  @Output() contactUsButtonEvent = new EventEmitter<object>();

  className = 'app-banner';

  constructor() {}

  ngOnInit(): void {}

  clickButtonContactUs($event: object): void {
    this.contactUsButtonEvent.emit($event);
  }
}