export const navigationPublic: any[] = [
  {
    id: 'public/_home',
    title: 'Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/public/_home'
  }
];
export const navigationUser: any[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/home'
  },
  {
    id: 'catalogue',
    title: 'Catalogue',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'widgets',
    url: '/catalogue'
  }
];
export const navigationAdmin: any[] = [
  {
    id: 'home',
    title: 'Private Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/home'
  },
  {
    id: 'public/_home',
    title: 'Public Home',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'home',
    url: '/public/_home'
  },
  {
    id: 'categories',
    title: 'Categories',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'category',
    url: 'admin/categories-list'
  },
  {
    id: 'applications',
    title: 'Applications',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'apps',
    url: 'admin/applications-list'
  },
  {
    id: 'landing',
    title: 'Public Landings',
    type: 'item',
    externalUrl: false,
    borderTop: false,
    icon: 'wifi_home',
    url: 'admin/landing-list'
  }
];