@if (isLoading) {
  <skeleton-app-detail
    [title2]="
      categorySuggested['applications'].length > 0 ? 'Suggested Apps' : ''
    "></skeleton-app-detail>
}
@if (!isLoading && id) {
  <app-application-detail
    (emitAddToLibrary)="addToLibrary($event)"
    (emitClickMore)="onClickMoreButton($event)"
    (emitContact)="contact($event)"
    [arrayApplicationFeatured]="arrayApplicationFeatured"
    [categoriesList]="categoriesList"
    [categoryFeaturedId]="categoryFeaturedId"
    [categoryFeatured]="categoryFeatured"
    [categoryMostPurchasedId]="categoryMostPurchasedId"
    [categoryMostPurchased]="categoryMostPurchased"
    [categoryPurchasedId]="categoryPurchasedId"
    [categoryPurchased]="categoryPurchased"
    [categorySpecialId]="categorySpecialId"
    [categorySpecial]="categorySpecial"
    [categorySuggestedId]="categorySuggestedId"
    [categorySuggested]="categorySuggested"
    [dataApplication]="dataApplication"
    [disabledButton]="disabledButton"
    [labels]="labels"
    [showAddToLibraryButton]="showAddToLibraryButton"
    [showAlreadyInLibraryButton]="showAlreadyInLibraryButton"
    [showContactButton]="showContactButton"
    [typeModal]="typeModal"></app-application-detail>
}