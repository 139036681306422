import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApplicationModel,
  CategoryApplicationModel,
  CategoryModel
} from '../../../common/models/common.model';
import { Subscription } from 'rxjs';
import { CategoriesStoreService } from '../../../common/services/category-observable.service';
import { StorageService } from '../../../common/services/storage.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../../common/services/utils.service';
import { ApplicationsService } from '../../../common/services/applications.service';
import { ResizeWindowService } from '../../../common/services/resize-window.service';
import { SnackMessageService } from '../../../common/services/snackMessage.service';
import { AuthService } from '../../../common/services/auth.service';

@Component({
  selector: 'catalogue-page',
  templateUrl: './catalogue-page.component.html',
  styleUrls: ['./catalogue-page.component.scss']
})
export class CataloguePageComponent implements OnInit, OnDestroy {
  theme: string = 'marketplace-default';
  isLoading = true;
  isDesktop: boolean;
  width: number;
  categoryFeaturedId: string;
  categorySpecialId: string;
  categoryPurchasedId: string;
  categoryMostPurchasedId: string;
  categorySuggestedId: string;
  categoryFeatured: CategoryModel;
  categorySpecial: CategoryModel;
  categoryPurchased: CategoryModel;
  categoryMostPurchased: CategoryModel;
  categorySuggested: CategoryModel = undefined;
  showText: boolean = true;

  className = 'catalogue-page';
  results: ApplicationModel[] | CategoryApplicationModel[];
  img = 'empty-search';
  title = 'No matched results';
  description = 'Check out the suggestions below or visit our catalogue to discover new apps.';
  buttonText = 'Go to Home page';
  buttonUrl = '/home';
  arrayApplicationFeatured: any = [];
  arraySubscriptions: Subscription[] = [];

  constructor(
    private categoriesStoreService: CategoriesStoreService,
    private applicationsService: ApplicationsService,
    private snackMessageService: SnackMessageService,
    private readonly authService: AuthService,
    private storageService: StorageService,
    private readonly resizeWindowService: ResizeWindowService,
    private readonly router: Router,
    private utilsSevice: UtilsService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.isLoading = true;
    this.initCategoryFeatured();
    this.initCategorySuggested();
    this.initSpecialCategory();
    this.initPurchasedCategory();
    this.initMostPurchasedCategory();
    this.initWidth();
    this.initDesktop();
    this.initApplications();
  }

  initApplications(): void {
    this.arraySubscriptions.push(
      this.applicationsService.getApplicationsSearch('', 0, 100).subscribe({
        next: (data: ApplicationModel[] | CategoryApplicationModel[]) => {
          this.results = data['applicationList'];
          this.addTagsList(this.results);
          this.storageService.resetBread();
          this.storageService.setBread('catalogue', 'widgets', 'Catalogue', undefined);
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
          if (this.authService.isLoggedIn()) {
            this.router.navigate(['home']);
          } else {
            this.authService.closeSession();
          }
        }
      })
    );
  }

  addTagsList(appsList) {
    let _appsList = [];
    appsList.forEach((app, index) => {
      _appsList.push(app);
      let _dataTagsList = [];
      if (app.categories.find((category) => category.type === 2) !== undefined) {
        if (!_dataTagsList.includes('In library')) {
          _dataTagsList.push('In library');
        }
      }
      if (app.categories.find((category) => category.type === 3) !== undefined) {
        if (!_dataTagsList.includes('Best Seller')) {
          _dataTagsList.push('Best Seller');
        }
      }
      if (
        app.categories.find((category) => category.type === 4) !== undefined &&
        app.price[0].value === 0
      ) {
        if (!_dataTagsList.includes('Free')) {
          _dataTagsList.push('Free');
        }
      }
      _appsList[index].dataTagList = _dataTagsList;
    });
    this.isLoading = false;
    return _appsList;
  }

  initDesktop() {
    this.arraySubscriptions.push(
      this.resizeWindowService.isDesktop$.subscribe({
        next: (value) => {
          this.isDesktop = value;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initWidth() {
    this.arraySubscriptions.push(
      this.resizeWindowService.width$.subscribe({
        next: (value) => {
          this.width = value;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategoryFeatured() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getFeaturedCategory().subscribe({
        next: (category) => {
          this.categoryFeatured = category;
          this.categoryFeaturedId = category._id;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initCategorySuggested() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSuggestedCategory().subscribe({
        next: (category) => {
          this.categorySuggested = category;
          this.categorySuggestedId = category._id;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initSpecialCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getSpecialCategory().subscribe({
        next: (category) => {
          this.categorySpecial = category;
          this.categorySpecialId = category._id;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getPurchasedCategory().subscribe({
        next: (category) => {
          this.categoryPurchased = category;
          this.categoryPurchasedId = category._id;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  initMostPurchasedCategory() {
    this.arraySubscriptions.push(
      this.categoriesStoreService.getMostPurchasedCategory().subscribe({
        next: (category) => {
          this.categoryMostPurchased = category;
          this.categoryMostPurchasedId = category._id;
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
          if (error.error.response !== undefined) {
            this.snackMessageService.readError(error);
          } else {
            this.snackMessageService.sendMessage(error.error);
          }
        }
      })
    );
  }

  refreshData() {
    //this.isLoading = true;
    this.removeSubscriptions();
    this.initSubscriptions();
  }

  removeSubscriptions() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }
}