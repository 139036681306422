<ng-container *ngIf="type === 'vertical'">
  <div class="{{ theme }} {{ className }}__wrapper vertical ">
    <div class="{{ className }}__skeleton vertical-img {{ size !== undefined ? size : '' }}"></div>
    <div class="{{ className }}__skeleton vertical-title"></div>
    <div class="{{ className }}__skeleton vertical-description"></div>
    <div class="{{ className }}__skeleton vertical-description" style="margin-bottom: 20px"></div>
    <div class="{{ className }}__skeleton vertical-barbara-icon"></div>
    <div class="{{ className }}__skeleton vertical-categories-list"></div>
  </div>
</ng-container>
<ng-container *ngIf="type === 'horizontal'">
  <div class="{{ theme }} {{ className }}__wrapper horizontal">
    <div class="{{ className }}__wrapper-col">
      <div class="{{ className }}__skeleton horizontal-img"></div>
    </div>
    <div class="{{ className }}__wrapper-col last">
      <div class="{{ className }}__skeleton horizontal-title"></div>
      <div class="{{ className }}__skeleton horizontal-description"></div>
      <div class="{{ className }}__wrapper-row">
        <div class="{{ className }}__skeleton horizontal-barbara-icon"></div>
        <div class="{{ className }}__skeleton horizontal-categories-list"></div>
      </div>
    </div>
  </div>
</ng-container>