@if (userLogIn && !isLoading) {
  <app-main-layout
    [isAdmin]="isAdmin"
    [isCompanyAdmin]="isCompanyAdmin"
    [isDesktop]="isDesktop"
    [showMenuEcosystem]=""
    [showMenuUser]=""
    [showMenu]=""
    [theme]="theme"
    [userAvatar]="userAvatar"
    [userName]="userName"
    [width]="width"></app-main-layout>
}
@if (!userLogIn && !isLoading) {
  <app-main-layout-public
    [isDesktop]="isDesktop"
    [showMenuEcosystem]=""
    [showMenu]=""
    [theme]="theme"
    [width]="width"></app-main-layout-public>
}