import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationModel, CategoryModel } from '../../models/common.model';
import { CategoriesStoreService } from '../../services/category-observable.service';
import { StorageService } from '../../services/storage.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../services/utils.service';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnChanges, OnDestroy {
  @Input() img: string;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() buttonUrl: string;
  @Input() theme: string = 'marketplace-default';
  @Input() itemsList: ApplicationModel[];
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() context: string;
  @Input() title2: string;
  @Input() categoryFeaturedId: string;
  @Input() categorySpecialId: string;
  @Input() categoryPurchasedId: string;
  @Input() categoryMostPurchasedId: string;
  @Input() categorySuggestedId: string;
  @Input() categoryFeatured: CategoryModel;
  @Input() categorySpecial: CategoryModel;
  @Input() categoryPurchased: CategoryModel;
  @Input() categoryMostPurchased: CategoryModel;
  @Input() categorySuggested: CategoryModel;
  @Input() showText: boolean = true;
  @Input() showButtons: boolean = true;
  @Input() showTagsList: boolean = true;
  @Output() closeMenuEmitter: EventEmitter<any> = new EventEmitter();
  className = 'app-empty';
  times: number[] = [];
  arrayApplicationFeatured: any = [];
  arraySubscriptions: Subscription[] = [];
  isLoading: boolean = false;

  constructor(
    private categoriesStoreService: CategoriesStoreService,
    private applicationsService: ApplicationsService,
    private storageService: StorageService,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsList !== undefined && changes.itemsList.firstChange) {
      this.prepareData(this.width);
      this.randomApp();
    }
    if (
      changes.width !== undefined &&
      !changes.width.firstChange &&
      changes.width.currentValue !== changes.width.previousValue
    ) {
      this.prepareData(this.width);
      this.randomApp();
    }
  }

  checkApps() {
    this.updateCategorySuggested();
  }

  updateCategorySuggested() {
    this.isLoading = true;
    this.applicationsService
      .getApplicationsSearch('', 0, 100, '', this.categorySuggestedId)
      .subscribe({
        next: (data) => {
          let applicationsList = data['applicationList'];
          this.utilsService.addTagsList(applicationsList);
          this.categorySuggested.applications = applicationsList;
          this.prepareData(this.width);
          this.randomApp();
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  randomApp() {
    let numberRandom = [];
    this.arrayApplicationFeatured = [];
    if (this.categorySuggested.applications[0].hasOwnProperty('dataTagList')) {
      if (this.categorySuggested.applications.length > this.times.length) {
        this.utilsService.randomNumber(
          this.categorySuggested.applications,
          numberRandom,
          this.times.length
        );
        numberRandom.forEach((idx) => {
          this.arrayApplicationFeatured.push(this.categorySuggested.applications[idx]);
        });
      } else {
        this.arrayApplicationFeatured = this.categorySuggested.applications;
      }
      this.isLoading = false;
    } else {
      this.checkApps();
    }
    return this.arrayApplicationFeatured;
  }

  prepareData(width) {
    this.times = this.utilsService.prepareCards(width);
    return this.times;
  }

  closeMenu($event, id): void {
    if (this.storageService.getCategoryId() !== id) {
      this.storageService.setCategoryId(id);
      this.closeMenuEmitter.emit({ action: 'refresh', event: id });
    } else {
      this.closeMenuEmitter.emit({ action: 'refresh', event: id });
    }
  }

  onClickMoreButton(id: string): void {
    this.storageService.setUrl('application-detail/' + id);
    this.categoriesStoreService.setStateMenu('close');
    this.router.navigate(['application-detail', id]);
  }

  ngOnDestroy() {
    this.arraySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}