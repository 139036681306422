<div class="{{ theme }} {{ className }}">
  <div class="{{ theme }} {{ className }}__wrapper">
    <h3 class="{{ theme }} {{ className }}__title">{{ _data.title1 }}</h3>
    <h3 *ngIf="_data.title2 !== undefined" class="{{ theme }} {{ className }}__title last">
      {{ _data.title2 }}
    </h3>
    <p class="{{ theme }} {{ className }}__text">{{ _data.text }}</p>
    <button
      (click)="clickButtonContactUs($event)"
      class="{{ theme }} {{ className }}__button big"
      color="accent"
      id="{{ _data.buttonId }}"
      mat-flat-button>
      {{ _data.button }}
    </button>
  </div>
  <img
    *ngIf="isDesktop"
    [src]="_data.srcImg"
    alt="banner image"
    class="{{ theme }} {{ className }}__image" />
  <img
    *ngIf="!isDesktop"
    [src]="_data.srcImgMobile"
    alt="banner image"
    class="{{ theme }} {{ className }}__image" />
</div>