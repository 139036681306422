<div
  class="{{ theme }} {{ className }}"
  id="card-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}">
  <div class="{{ theme }} {{ className }}__wrapper-image">
    @if (_data.pictures?.length === 0) {
      <img
        (click)="clickButtonMore(_data._id, _data.name, $event)"
        src="/assets/images/empty/empty-image.svg"
        alt="app image"
        class="{{ theme }} {{ className }}__image" />
    } @else {
      <img
        (click)="clickButtonMore(_data._id, _data.name, $event)"
        [src]="_data.pictures[0]"
        alt="app image"
        class="{{ theme }} {{ className }}__image" />
    }

    @if (showTagsList && _data.dataTagList !== undefined) {
      <ul class="{{ theme }} {{ className }}__tags-list">
        <li class="{{ theme }} {{ className }}__tags-list-item most-purchased">
          @if (_data.dataTagList.includes('Best Seller')) {
            <div class="{{ theme }} {{ className }}__tags-list-item-shape most-purchased">
              <mat-icon
                class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
                {{ categoryMostPurchased.icon }}
              </mat-icon>
              <span class="{{ theme }} {{ className }}__tags-list-item-text"> Best Seller </span>
            </div>
          }
        </li>

        <li class="{{ theme }} {{ className }}__tags-list-item purchased">
          @if (_data.dataTagList.includes('In library')) {
            <div class="{{ theme }} {{ className }}__tags-list-item-shape purchased">
              <mat-icon
                class="material-icons-outlined {{ theme }} {{ className }}__tags-list-item-icon">
                check_small
              </mat-icon>
              <span class="{{ theme }} {{ className }}__tags-list-item-text"> In library </span>
            </div>
          }
        </li>
      </ul>

      @if (_data.dataTagList.includes('Free')) {
        <ul class="{{ theme }} {{ className }}__tags-list free">
          <li class="{{ theme }} {{ className }}__tags-list-item free">
            <div class="{{ theme }} {{ className }}__tags-list-item-shape free">
              <span class="{{ theme }} {{ className }}__tags-list-item-text"> Free </span>
            </div>
          </li>
        </ul>
      }
    }
    @if (showButtons && !purchased) {
      @if (showContactButton) {
        <button
          (click)="contact(_data._id)"
          [disabled]="disabledButton"
          class="big {{ theme }} {{ className }}__button-add"
          color="accent"
          id="contact-button"
          matTooltip="{{ labels.requestInfo }}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above"
          mat-mini-fab>
          <mat-icon>contact_support</mat-icon>
        </button>
      }
      @if (showAddToLibraryButton) {
        <button
          (click)="addToLibrary(_data._id, typeModal)"
          [disabled]="disabledButton"
          class="big {{ theme }} {{ className }}__button-add"
          color="accent"
          id="add-to-library-button"
          matTooltip="{{ labels.addToLibrary }}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above"
          mat-mini-fab>
          <mat-icon>add</mat-icon>
        </button>
      }
    }
  </div>
  <div
    (click)="clickButtonMore(_data._id, _data.name, $event)"
    class="{{ theme }} {{ className }}__body"
    id="card-body-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}">
    <div class="{{ theme }} {{ className }}__body-wrapper">
      <h3
        class="{{ theme }} {{ className }}__title"
        matTooltip="{{ _data.name }}"
        matTooltipClass="{{ theme }} custom-tooltip"
        matTooltipPosition="above">
        {{ _data.name }}
      </h3>
    </div>
    <div
      [innerHtml]="_data.shortDescription | safe: 'html'"
      class="{{ theme }} {{ className }}__text"
      id="card-description-{{ utilsService.removeSpacesAndInsertScript(_data.name) }}"
      matTooltip=" {{ shortDescriptionTooltip }}"
      matTooltipClass="{{ theme }} custom-tooltip"
      matTooltipPosition="above">
      {{ shortDescriptionTooltip }}
    </div>
    <ul class="{{ theme }} {{ className }}__category-tags-list">
      @for (category of categoriesTags; track $index) {
        @if (category.type === 4) {
          <li class="{{ theme }} {{ className }}__category-tags-list-item order">
            <div class="{{ theme }} {{ className }}__category-tags-list-item-shape">
              <img alt="barbara icon" src="assets/images/icons/b_white.svg" />
            </div>
          </li>
        } @else {
          @if (category.type === 0) {
            <li class="{{ theme }} {{ className }}__category-tags-list-item">
              <span class="{{ theme }} {{ className }}__category-tags-list-item-text">
                {{ category.name }}
              </span>
            </li>
          }
        }
      }
    </ul>
  </div>
</div>